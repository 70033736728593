/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { getSessionClientId } from 'helpers/sessionService';
import { exportFileParser } from 'utils/parsers/exportFileParser';
import { reportExtensions } from 'constants/reportExtensions';
import { getPermissions } from 'services/auth';
import { createNotification, getNotification } from 'services/notifications';
import errorHandler from 'helpers/errorHandler';
import dayjs from 'dayjs';

// TODO: this hook should be refactored to use camelcase
export const useReport = ({
  fileName,
  table,
  startDate,
  endDate,
  toParamsFilters,
  message = 'Preparando relatório',
  description = 'Estamos preparando seu relatório. Assim que ele estiver pronto, ficará disponível para download na área de notificações.',
  clientName = undefined,
  extraFilters = undefined,
  sortFields = undefined,
}) => {
  const [dropdownOption, setDropdownOption] = useState('');
  const [userId, setUserId] = useState();
  const [scopeId, setScopeId] = useState();
  const [profileId, setProfileId] = useState();
  const [loading, setLoading] = useState(false);
  const [createNotificationSuccess, setCreateNotificationSuccess] =
    useState(false);

  const clientId = getSessionClientId();

  const fetchPermissions = async () => {
    setLoading(true);

    try {
      const { user_id, scope_id, profile_id } = await getPermissions();

      setUserId(user_id);
      setScopeId(scope_id);
      setProfileId(profile_id);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
    }

    return setLoading(false);
  };

  const createNewNotification = async (config) => {
    setLoading(true);
    setCreateNotificationSuccess(false);

    try {
      await createNotification(config);
      setDropdownOption('');
      setCreateNotificationSuccess(true);

      return notification.success({
        message,
        duration: 8,
        description,
        props: {
          'data-testid': 'notification-preparing-report',
        },
      });
    } catch (error) {
      errorHandler('Ocorreu um erro:', error);
    }

    return setLoading(false);
  };

  const getNotifications = async () => {
    setLoading(true);

    try {
      await getNotification({
        userId,
        scopeId,
        clientId,
        page: 1,
        perPage: 10,
      });

      setLoading(false);
    } catch (error) {
      errorHandler('Ocorreu um erro:', error);
    }

    return setLoading(false);
  };

  const buildExtraFilters = (filtersObj) => {
    const result = Object.entries(filtersObj)
      .filter(([k, v]) => k !== undefined && v !== undefined)
      .map(([key, value]) => {
        return {
          name: key,
          val: [value],
        };
      });

    return result;
  };

  const formattedFileName = `${fileName}.${reportExtensions[dropdownOption]}`;

  const format = 'YYYY-MM-DD';

  const notificationConfig = {
    message: `Estamos preparando seu arquivo ${formattedFileName} para o download.`,
    table,
    fileType: reportExtensions[dropdownOption],
    startDate: startDate ? dayjs(startDate).format(format) : undefined,
    endDate: endDate ? dayjs(endDate).format(format) : undefined,
    q: {
      filter: toParamsFilters
        ? exportFileParser(toParamsFilters)
        : extraFilters
          ? buildExtraFilters(extraFilters)
          : [],
    },
    clientName,
    profileId,
    scopeId,
    sortFields: JSON.stringify(sortFields),
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (dropdownOption === 'excel' || dropdownOption === 'csv') {
      createNewNotification(notificationConfig);
    }
  }, [dropdownOption]);

  useEffect(() => {
    if (createNotificationSuccess) {
      getNotifications();
    }
  }, [createNotificationSuccess]);

  return {
    dropdownOption,
    setDropdownOption,
    loading,
  };
};
